import React from 'react'

import { isMobile } from 'react-device-detect'
import Avatar from '~/components/Avatar'
import BlobsSignature from '~/components/BlobsSignature'
import SectionDivider from '~/components/SectionDivider'
import RevealFade from '~/components/RevealFade'

import * as style from './styles'

const WATCHING_WII_IMAGE = 'static/images/misc/watching-wii.png'
const WII_LINK = 'https://wii.josephtaouk.com/'

const VersionIndicator = () => (
  <div css={style.versionIndicator}>
    Last Design Update: November 29, 2020
    <br />
    Last Content Update: August 22, 2024
  </div>
)

const IntroSection = ({ theme }) => {
  return (
    <section css={style.sectionIntro({ theme })}>
      {
        !isMobile && (
          <a href={WII_LINK}>
            <img src={WATCHING_WII_IMAGE} css={style.watchingWiiImage} />
          </a>
        )
      }
      <div css={style.blobSignatureContainer}>
        <RevealFade left>
          <BlobsSignature theme={theme} />
        </RevealFade>
      </div>
      <div css={style.avatarContainer}>
        <RevealFade right>
          <Avatar />
        </RevealFade>
      </div>
      <VersionIndicator />
      <SectionDivider theme={theme} css={style.divider} />
    </section>
  )
}

export default IntroSection
