import React, { useState } from 'react'

import SectionTitle from '~/components/SectionTitle'
import { useTransition, useTrail, animated } from 'react-spring'

import historyFixture from '~/fixtures/history'

import * as style from './styles'

const Title = () => (
  <SectionTitle animation='travelWorld' blob={5}>
    Experience
  </SectionTitle>
)

const ExperiencePicker = ({ selectedExperience, onChange }) => {
  const trail = useTrail(historyFixture.length, {
    config: { mass: 1, tension: 120, friction: 14 },
    from: {
      x: 100,
    },
    to: {
      x: 0,
    },
  })

  return (
    <div css={style.experiencePicker}>
      {trail.map(({ x, ...rest }, index) => {
        const { label, image, startDate, endDate } = historyFixture[index]
        const isSelected = selectedExperience === index

        return (
          <animated.div
            key={`history_option-${label}`}
            style={{ ...rest, transform: x.interpolate(x => `translate3d(${x}vw,0,0)`) }}
            css={style.experience(isSelected)}
            onClick={() => onChange(index)}
          >
            <img src={image} />
            <label>{label}</label>
              ({startDate} - {endDate})
          </animated.div>
        )
      })}
    </div>
  )
}

const Tags = ({ label, tags, tagsColor }) => {
  if (!tags) {
    return null
  }

  return (
    <div css={style.tagsContainer}>
      {
        tags.map(tag => (
          <small
            key={`history_description-${label}-${tag}`}
            css={style.tagChip(tagsColor)}
          >
            {tag}
          </small>
        ))
      }
    </div>
  )
}

const ExperienceDescription = ({ selectedExperience }) => {
  if (selectedExperience === null) {
    return <div css={style.descriptionContainer} />
  }

  const transitions = useTransition([historyFixture[selectedExperience]], item => item.label, {
    from: { transform: 'translateX(100vw)' },
    enter: { transform: 'translateX(0vw)' },
    leave: { transform: 'translateX(-100vw)' },
  })

  return (
    <div css={style.descriptionContainer}>
      {
        transitions.map(({ item, props }) => (
          <animated.div
            key={`history_description-${item.label}`}
            style={props}
            css={style.description}
          >
            <p>
              <b>Location:</b> {item.location}
              <br />
              <b>Position:</b> {item.title}
              {
                item.url && (
                  <>
                    <br />
                    <b>Link:</b> <a href={item.url} target='_blank'>{item.url}</a>
                  </>
                )
              }
            </p>
            <p>
              {item.description}
            </p>
            <Tags {...item} />
          </animated.div>
        ))
      }
    </div>
  )
}

const ExperienceInteractive = () => {
  const [selectedExperienceIndex, setSelectedExperienceIndex] = useState(null)

  return (
    <>
      <ExperiencePicker
        selectedExperience={selectedExperienceIndex}
        onChange={setSelectedExperienceIndex}
      />
      <ExperienceDescription
        selectedExperience={selectedExperienceIndex}
      />
    </>
  )
}

const ExperienceSection = ({ theme }) => {
  const resumeURL = process.env.RESUME_URL;

  return (
    <section css={style.sectionExperience({ theme })}>
      <Title />
      <ExperienceInteractive />
      <div  css={style.resumeLinkContainer}>
        <a href={resumeURL} target='_blank'>
          👉 Looking for a resume? 📄
        </a>
      </div>
    </section>
  )
}

export default ExperienceSection
